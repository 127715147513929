/**
 * TODO Add a symbol for copying an anchor link
 */
import { useLocation } from "@remix-run/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { getLocale } from "~/i18n";

interface Props {
  children: React.ReactNode,
  className?: string,
}

const H1: React.FC<Props> = (
  {
    children,
    className = null,
  }
) => {
  const locale = getLocale(useLocation().pathname);

  const [isCapitalized, setIsCapitalized] = useState(locale === "en");

  useEffect(() => {
    setIsCapitalized(locale === "en");
  }, [locale]);


  return (
    <h1 className={classNames(
      "text-3xl md:text-4xl text-slate-900 dark:text-white font-bold",
      {
        "capitalize": isCapitalized,
      },
      className
    )}>
      {children}
    </h1>
  );
};

export default H1;